<template>
  <div class="card-files">
    <div class="card-file-body">
      <div
        v-if="!isRequest"
        class="btn-upload-file"
        role="button"
        tabindex="0"
        @click="handleOpenFileMain"
      >
        <div class="card-file-body-content">
          <img :src="fileImg" alt="logo file" width="60" height="60" />
          <span class="file-add-icon">
            <feather-icon
              icon="PlusCircleIcon"
              stroke="#fff"
              fill="#787878"
              size="22"
            />
          </span>
          <p class="cart-file-text">
            Images of Product
            <span v-if="images.length">({{ images.length }})</span>
          </p>
        </div>
      </div>
      <div v-for="(item, index) in images" :key="index" class="image-catalog">
        <img
          v-if="item.path"
          T
          :src="item.path"
          alt="image catalog"
          class="image"
        />
        <img v-else :src="item" alt="image catalog" class="image" />
        <div class="image-controlles">
          <b-button
            v-if="item.id && !isRequest"
            size="sm"
            variant="primary"
            @click="setIsMain(item)"
          >
            {{ $t("set_as_main") }}
          </b-button>
        </div>

        <button
          v-if="!isRequest"
          type="button"
          aria-hidden="true"
          aria-expanded="false"
          aria-label="Delete"
          class="btn-delete"
          @click="$emit('delete-images', { item, index })"
        >
          <span class="delete-icon">X</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { BButton } from "bootstrap-vue";

export default {
  components: { BButton },
  props: ["images", "isRequest", "isEdit", "slug"],
  data() {
    return {
      images_Url: [],
      fileImg: require("@/assets/images/logo/picture.svg"),
    };
  },

  // watch: {
  //   images() {
  //     if (!this.isRequest) {
  //       this.images_Url = [];
  //       this.images.forEach((file) => {
  //         this.generateImageUrl(file);
  //       });
  //     }
  //   },
  // },

  beforeMount() {
    if (this.isRequest || this.isEdit) {
      this.images_Url = this.images;
    }
  },

  methods: {
    generateImageUrl(file) {
      if (file.id) {
        this.images_Url.push(file);
        return;
      }
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        this.images_Url.push(e.target.result);
      };
    },

    handleOpenFileMain() {
      document.getElementById("product-files").click();
    },
    async setIsMain(item) {
      try {
        this.imageLoading = true;
        if (item.id) {
          const formData = new FormData();

          formData.append("image_id", item.id);
          formData.append("is_main", 1);
          await this.$http.post(
            `admin/catalogs/${this.slug}?_method=put`,
            formData
          );
          this.$emit("handel-is-main-change", { path: item.path, id: item.id });
        } else {
          this.$emit("handel-is-main-change", { file: item });
        }
      } catch (err) {
        this.$helpers.handleError(err);
      } finally {
        this.imageLoading = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.card-file-body {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  overflow-x: scroll;
  overflow-y: hidden;
  padding-right: 1rem;
  gap: 10px;
  .btn-upload-file {
    cursor: pointer;
    width: 180px;
    height: 160px;
  }

  .card-file-body-content {
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 180px;

    .file-add-icon {
      position: absolute;
      top: 80px;
      left: 105px;
    }

    .cart-file-text {
      margin-top: 0.8rem;
      font-weight: 400;
      font-size: 1rem;
      color: #787878;
    }
  }

  .image-catalog {
    position: relative !important;
    .image {
      object-fit: contain;
      width: 180px;
      height: 150px;
      border-radius: 5px;
      z-index: 1;
    }
    .image-controlles {
      background-color: #0b0b0b;
      width: 180px;
      height: 150px;
      opacity: 0;
      transition: 0.5s;
      position: absolute !important ;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      top: 0px !important;
      flex-direction: column;
      background-color: rgba(0, 0, 0, 0.671);
    }
    .image-controlles:hover {
      opacity: 1;
    }
    &:hover {
      .btn-delete {
        opacity: 1;
      }
    }

    .btn-delete {
      position: absolute;
      top: 0.5rem;
      right: 1rem;
      background-color: #0005;
      border: 0;
      border-radius: 50px;
      width: 22px;
      height: 22px;
      opacity: 0;
      transition: opacity 0.3s ease-out;

      .delete-icon {
        color: red;
        font-size: 1rem;
      }
    }
  }
}
</style>
