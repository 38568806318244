<template>
  <b-modal
    centered
    no-close-on-backdrop
    hide-header-close
    id="image-cropper-component"
    size="md"
    @ok="handelSendCroppedImage"
    @cancel="handelCancel"
    :cancel-title="$t('cancel-title')"
    :ok-title="$t('ok-title')"
  >
    <cropper
      class="cropper"
      ref="cropper"
      :src="activeImagePath"
      :default-size="defaultSize"
      @change="change"
    />
    <b-row
      v-if="localImagesArray.length > 1"
      class="d-flex justify-content-center my-1"
    >
      <b-col cols="12" class="d-flex justify-content-center">
        <button style="all: unset" @click="revertChanges">
          <feather-icon class="undo-icon" icon="RotateCcwIcon" size="20" />
        </button>
      </b-col>
    </b-row>
    <b-row
      v-if="localImagesArray.length > 1"
      class="d-flex flex-row justify-content-around"
    >
      <b-col cols="2" v-for="image of localImagesArray">
        <button
          :class="{
            'img-preview': true,
            active: image.active,
          }"
          @click="switchActiveImage(image)"
        >
          <img :src="image.path" />
        </button>
      </b-col>
    </b-row>
  </b-modal>
</template>
<script>
import { BModal, BRow, BCol } from "bootstrap-vue";
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

export default {
  name: "imageCropperComponent",
  props: {
    imagesArray: {
      required: false,
    },
    imageType: {
      required: false,
    },
  },
  components: {
    BModal,
    BRow,
    BCol,
    Cropper,
  },
  data() {
    return {
      show: true,
      img: require("@/assets/images/logo/logo.png"),
      canvas: null,
      localImagesArray: [],
      ArchiveImagesArray: [],
      activeImagePath: null,
    };
  },
  methods: {
    defaultSize({ imageSize, visibleArea }) {
      return {
        width: (visibleArea || imageSize).width,
        height: (visibleArea || imageSize).height,
      };
    },
    change() {
      const { canvas } = this.$refs.cropper.getResult();
      const activeImageIndex = this.localImagesArray.findIndex(
        (ele) => ele.active === true
      );
      const imageType = this.ArchiveImagesArray[activeImageIndex].file.type;
      canvas.toBlob((blob) => {
        this.localImagesArray[activeImageIndex] = {
          originalFile: this.ArchiveImagesArray[activeImageIndex].file,
          active: true,
          file: blob,
          path: URL.createObjectURL(blob),
        };
      }, imageType);
    },
    handelSendCroppedImage() {
      if (true === this.imageType) {
        this.$emit("return-main-image", this.localImagesArray[0]);
      } else {
        this.$emit("return-not-main-image", this.localImagesArray);
      }
      this.$emit("cancel");
      this.clearImages();
    },
    handelCancel() {
      const activeImageIndex = this.localImagesArray.findIndex(
        (ele) => ele.active === true
      );
      this.localImagesArray.map((image) => {
        if (image.hasOwnProperty("originalFile")) {
          image.file = this.ArchiveImagesArray[activeImageIndex].file;
          image.path = URL.createObjectURL(
            this.ArchiveImagesArray[activeImageIndex].file
          );
          return image;
        }
      });
      if (true === this.imageType) {
        this.$emit("handel-cancel-main-image", this.localImagesArray[0]);
      } else {
        this.$emit("handel-cancel-not-main-image", this.localImagesArray);
      }
      this.$emit("cancel");

      this.clearImages();
    },
    switchActiveImage(image) {
      this.localImagesArray = this.localImagesArray.map((loopImageObj) => {
        if (loopImageObj.path === image.path) {
          this.activeImagePath = loopImageObj.path;
          return { ...loopImageObj, active: true };
        } else {
          return { ...loopImageObj, active: false };
        }
      });
    },
    revertChanges() {
      const activeImageIndex = this.localImagesArray.findIndex(
        (ele) => ele.active === true
      );
      const { originalFile } = this.localImagesArray[activeImageIndex];
      this.localImagesArray[activeImageIndex].file = originalFile;
      this.localImagesArray[activeImageIndex].path =
        URL.createObjectURL(originalFile);
      this.activeImagePath = this.localImagesArray[activeImageIndex].path;
    },
    clearImages() {
      this.localImagesArray.length = 0;
      this.ArchiveImagesArray.length = 0;
    },
  },
  watch: {
    imagesArray(newValue) {
      this.localImagesArray.length = 0;
      this.ArchiveImagesArray.length = 0;
      if (Array.isArray(newValue)) {
        newValue.forEach((ele) => {
          this.localImagesArray.push({ ...ele, active: false });
          this.ArchiveImagesArray.push({ ...ele, active: false });
        });
      } else {
        this.localImagesArray.push({ ...newValue, active: false });
        this.ArchiveImagesArray.push({ ...newValue, active: false });
      }
      this.localImagesArray[0].active = true;
      this.activeImagePath = this.localImagesArray[0].path;
    },
  },
};
</script>
<style lang="scss">
.cropper {
  height: 300px;
  .vue-advanced-cropper__boundaries {
    width: 100%;
  }
}
.img-preview {
  all: unset;
  img {
    height: 50px;
    width: 50px;
  }
  &.active {
    img {
      opacity: 0.6;
    }
  }
}
.undo-icon:hover {
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.7);
  color: red;

  opacity: 0.8;
}
/* RTL styles */
[dir="rtl"] .cropper-container {
  direction: ltr !important;
  left: auto !important;
  right: 0 !important;
}

[dir="rtl"] .cropper-crop-box {
  direction: ltr !important;

  left: auto !important;
  right: 0 !important;
}
[dir="rtl"] .vue-advanced-cropper {
  direction: ltr !important;
}
</style>
