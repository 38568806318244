<template>
  <div :class="{ 'card-file': true, active: !localImage }">
    <img
      v-if="localImage"
      :src="localImage"
      alt="logo file"
      :class="{ image: true }"
    />
    <div v-if="!isRequest" @click="handleOpenFileMain" class="overlay">
      <span class="file-add-icon">
        <img :src="fileImg" alt="logo file" width="60" height="60" />
        <feather-icon
          icon="PlusCircleIcon"
          stroke="#fff"
          fill="#787878"
          size="22"
        />
      </span>
      <p class="cart-file-text">{{ $t("g.uploadImage") }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: ["image", "isRequest", "isEdit", "localImage"],
  data() {
    return {
      image_url: null,
      loading: false,
      fileImg: require("@/assets/images/logo/picture.svg"),
    };
  },
  watch: {
    image() {
      if (!this.isRequest) {
        this.generateImageUrl(this.image);
      }
    },
  },
  beforeMount() {
    if (this.isRequest || this.isEdit) {
      this.image_url = this.image;
    }
  },
  methods: {
    generateImageUrl(imageFile) {
      this.loading = true;
      const reader = new FileReader();
      reader.readAsDataURL(imageFile);
      reader.onload = (e) => {
        this.image_url = e.target.result;
        this.loading = false;
      };
    },

    handleOpenFileMain() {
      document.getElementById("main-file").click();
    },
  },
};
</script>

<style scoped lang="scss">
.card-file {
  overflow: hidden;
  position: relative;
  .overlay {
    background-color: rgba(0, 0, 0, 0.671);
    position: absolute;
    top: 0px;
    color: white;
    height: 100%;
    width: 100%;
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .file-add-icon {
      position: relative;
      svg {
        position: absolute;
        bottom: 0px;
        right: 0px;
      }
    }
  }
  &.active,
  &:hover {
    .overlay {
      display: flex;
    }
  }
}

.loading-center {
  transform: translate(0px, 5rem);
  text-align: center;
}
.image {
  object-fit: contain;
  width: 180px;
  height: 150px;
  transform: translate(2px, 0.6rem);
}
.card-file-body {
  cursor: pointer;
  height: 100%;

  .card-file-body-content {
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    align-items: center;
    height: 100%;

    .file-add-icon {
      position: absolute;
      top: 80px;
      left: 105px;
    }

    .cart-file-text {
      margin-top: 0.8rem;
      font-weight: 400;
      font-size: 1rem;
      color: #787878;
    }
  }
}
</style>
