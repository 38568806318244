<template>
  <div>
    <loading v-if="isLoading" />

    <!-- form -->
    <validation-observer v-else ref="form">
      <b-form>
        <b-card class="card-add-catalog">
          <b-row>
            <!-- ** name -->
            <b-col md="6">
              <b-form-group :label="$t('g.name')" label-for="name">
                <validation-provider
                  #default="{ errors }"
                  name="Name"
                  rules="required"
                >
                  <b-form-input
                    v-model="form_data.name"
                    :state="errors.length > 0 ? false : null"
                    type="text"
                    :disabled="isRequest"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- **  name -->

            <!-- ** barcode -->
            <b-col md="6">
              <b-form-group :label="$t('g.barcode')" label-for="barcode">
                <validation-provider #default="{ errors }" name="Barcode">
                  <b-form-input
                    v-model="form_data.barcode"
                    :state="errors.length > 0 ? false : null"
                    type="number"
                    :disabled="isRequest"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- **  barcode -->

            <!-- Country of Manufacture -->
            <b-col md="6">
              <b-form-group
                :label="$t('g.CountryOfManufacture')"
                label-for="CountryOfManufacture"
              >
                <validation-provider
                  #default="{ errors }"
                  name="CountryOfManufacture"
                >
                  <b-form-input
                    v-model="form_data.made_in"
                    :state="errors.length > 0 ? false : null"
                    type="text"
                    :disabled="isRequest"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- ** Country of Manufacture -->
            <!-- Brand -->
            <b-col md="6">
              <b-form-group :label="$t('g.brand')" label-for="Brand">
                <validation-provider
                  #default="{ errors }"
                  name="Brand"
                  rules="required"
                >
                  <b-form-select
                    v-model="form_data.brand_id"
                    :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                    :options="brand_options"
                    label="position"
                    :state="errors.length > 0 ? false : null"
                    :disabled="isRequest"
                  />

                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- ** Brand -->

            <!-- Category -->
            <b-col md="6">
              <b-form-group :label="$t('g.Category')" label-for="Category">
                <validation-provider
                  #default="{ errors }"
                  name="Category"
                  rules="required"
                >
                  <b-form-select
                    v-model="form_data.category_id"
                    :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                    :options="category_options"
                    label="position"
                    :state="errors.length > 0 ? false : null"
                    :disabled="isRequest"
                  />

                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- ** Category -->

            <!-- Main Image -->
            <b-col md="3">
              <b-form-group :label="$t('g.MainImage')" label-for="Main Image">
                <validation-provider #default="{ errors }" name="Main Image">
                  <!-- -->
                  <input-main-image
                    :is-request="isRequest"
                    :is-edit="isEdit"
                    :image="image_main"
                    :local-image="localMainImageLink"
                  />
                  <b-form-file
                    v-if="!isRequest"
                    id="main-file"
                    @change="handelChangeMainImage"
                    v-model="mainImageFile"
                    :state="errors.length > 0 ? false : null"
                    type="file"
                    plain
                    hidden="hidden"
                    class="d-none"
                    accept="image/*"
                    length="1"
                  />

                  <RecommendationUploadImage
                    v-if="!mainImageFile || mainImageFile.length == 0"
                    :dimensions="{ width: 1024, height: 1024 }"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- ** Main Image -->

            <!-- Images Of Product -->
            <b-col md="9">
              <b-form-group
                :label="$t('g.ImagesOfProduct')"
                label-for="Images of Product"
              >
                <!-- -->
                <input-product-images
                  :slug="$route.params.id"
                  :is-request="isRequest"
                  :is-edit="isEdit"
                  :images="notMainImage"
                  @delete-images="deleteImages"
                  @handel-is-main-change="handelIsMainChange"
                />
                <b-form-file
                  v-if="!isRequest"
                  id="product-files"
                  v-model="images"
                  type="file"
                  plain
                  hidden
                  @change="handelChangeImages"
                  accept="image/*"
                  multiple
                />
              </b-form-group>
              <RecommendationUploadImage
                :dimensions="{ width: 1024, height: 1024 }"
              />
            </b-col>
            <!-- ** Images Of Product -->
            <b-col md="12">
              <div class="switches-row">
                <b-form-group
                  :label="$t('g.is_available')"
                  label-for="is_available"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="is_available"
                    rules="required"
                  >
                    <b-form-checkbox
                      v-model="form_data.is_available"
                      name="check-button"
                      switch
                      :disabled="isRequest"
                    />

                    <small class="text-danger">{{
                      errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                    }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  :label="$t('g.is_rateable')"
                  label-for="is_rateable"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="is_rateable"
                    rules="required"
                  >
                    <b-form-checkbox
                      v-model="form_data.is_rateable"
                      name="check-button"
                      switch
                      :disabled="isRequest"
                    />

                    <small class="text-danger">{{
                      errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                    }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  :label="$t('g.is_buyable')"
                  label-for="is_buyable"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="is_buyable"
                    rules="required"
                  >
                    <b-form-checkbox
                      v-model="form_data.is_buyable"
                      name="check-button"
                      switch
                      :disabled="isRequest"
                    />

                    <small class="text-danger">{{
                      errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
            </b-col>
            <b-col v-if="form_data.is_physical">
              <div class="isPhsyica-form row">
                <b-col md="6">
                  <b-form-group :label="$t('catalogs.width')" label-for="width">
                    <validation-provider #default="{ errors }" name="width">
                      <b-input-group>
                        <template #append>
                          <b-input-group-text
                            >{{ $t("g.cm") }}
                          </b-input-group-text>
                        </template>
                        <b-form-input
                          v-model="form_data.dimensions.width"
                          type="number"
                          :state="errors.length > 0 ? false : null"
                          :disabled="isRequest"
                        />
                      </b-input-group>
                      <small class="text-danger">{{
                        errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                      }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    :label="$t('catalogs.height')"
                    label-for="height"
                  >
                    <validation-provider #default="{ errors }" name="height">
                      <b-input-group>
                        <template #append>
                          <b-input-group-text
                            >{{ $t("g.cm") }}
                          </b-input-group-text>
                        </template>
                        <b-form-input
                          v-model="form_data.dimensions.height"
                          type="number"
                          :state="errors.length > 0 ? false : null"
                          :disabled="isRequest"
                        />
                      </b-input-group>
                      <small class="text-danger">{{
                        errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                      }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    :label="$t('catalogs.length')"
                    label-for="length"
                  >
                    <validation-provider #default="{ errors }" name="length">
                      <b-input-group>
                        <template #append>
                          <b-input-group-text
                            >{{ $t("g.cm") }}
                          </b-input-group-text>
                        </template>
                        <b-form-input
                          v-model="form_data.dimensions.length"
                          type="number"
                          :state="errors.length > 0 ? false : null"
                          :disabled="isRequest"
                        />
                      </b-input-group>
                      <small class="text-danger">{{
                        errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                      }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    :label="$t('catalogs.weight')"
                    label-for="weigth"
                  >
                    <validation-provider #default="{ errors }" name="weight">
                      <b-input-group>
                        <template #append>
                          <b-input-group-text
                            >{{ $t("g.kg") }}
                          </b-input-group-text>
                        </template>
                        <b-form-input
                          v-model="form_data.dimensions.weight"
                          type="number"
                          :state="errors.length > 0 ? false : null"
                          :disabled="isRequest"
                        />
                      </b-input-group>
                      <small class="text-danger">{{
                        errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                      }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </div>
            </b-col>

            <!-- Description -->
            <b-col md="12">
              <b-form-group :label="$t('g.description')" label-for="Brand">
                <validation-provider #default="{ errors }" name="Description">
                  <b-form-textarea
                    v-model="form_data.description"
                    :state="errors.length > 0 ? false : null"
                    rows="4"
                    :disabled="isRequest"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- ** Description -->
          </b-row>
        </b-card>

        <b-row>
          <!-- Submit Button -->
          <b-col cols="12">
            <div class="text-right">
              <b-button
                v-if="isRequest"
                variant="outline-primary"
                type="button"
                @click.prevent="$bvModal.show('show-modal-catalog-request')"
              >
                <span> {{ $t("g.reject") }}</span>
              </b-button>
              <b-button
                variant="primary"
                type="submit"
                :disabled="isPending"
                class="ml-1"
                @click.prevent="validationForm"
              >
                <b-spinner v-if="isPending" small label="Small Spinner" />
                <span v-else-if="isRequest">{{ $t("g.accept") }}</span>
                <span v-else>
                  {{ isEdit ? $t("g.saveChanges") : $t("g.addCatalog") }}</span
                >
              </b-button>
            </div>
          </b-col>
          <!-- ** Submit Button -->
        </b-row>
      </b-form>
    </validation-observer>
    <b-modal
      :title="$t('g.reject_catalog')"
      centered
      id="show-modal-catalog-request"
      modal-class="add-modal"
      title-class="add-modal-title"
      header-class="add-modal-header"
      footer-class="add-modal-footer"
      cancel-variant="outline-primary"
      hide-footer
      @close="handleReset"
    >
      <div>
        <validation-observer ref="cReject">
          <b-form>
            <b-form-group :label="$t('g.rejection_reason')">
              <validation-provider
                #default="{ errors }"
                name="Rejection reason"
                rules="required"
              >
                <b-form-textarea
                  rows="5"
                  v-model="message"
                  :state="errors.length > 0 ? false : null"
                  :placeholder="$t('g.rejection_reason')"
                />
                <small class="text-danger">{{
                  errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                }}</small></validation-provider
              >
            </b-form-group>
            <b-button variant="danger" @click="handelDeleteRequest">
              {{ $t("g.submit") }}
            </b-button>
          </b-form>
        </validation-observer>
      </div>
    </b-modal>
    <image-cropper-component
      :images-array="cropImages"
      :image-type="isCropMain"
      @return-not-main-image="handelCroppedNotMainImages"
      @return-main-image="handelCroppedMainImages"
      @handel-cancel-main-image="handelCroppedMainImages"
      @handel-cancel-not-main-image="handelCroppedNotMainImages"
    />
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import imageCropperComponent from "@/components/shared/ImageCropperComponent/index.vue";
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BCard,
  BFormSelect,
  BFormTextarea,
  BFormFile,
  BSpinner,
  BFormCheckbox,
  BInputGroup,
  BInputGroupText,
  BModal,
} from "bootstrap-vue";
import { required, confirmed, min, integer } from "@validations";
import Loading from "@/components/shared/loading/loading.vue";
import inputProductImages from "@/components/catalogs/add/inputProductImages.vue";
import inputMainImage from "@/components/catalogs/add/inputMainImage.vue";
import RecommendationUploadImage from "@/components/shared/RecommendationUploadImage/index";
import ImageCropperComponent from "@/components/shared/ImageCropperComponent/index.vue";

export default {
  components: {
    ImageCropperComponent,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormSelect,
    BCard,
    Loading,
    BFormTextarea,
    BFormFile,
    inputMainImage,
    inputProductImages,
    BSpinner,
    BFormCheckbox,
    BInputGroup,
    BInputGroupText,
    BModal,
    RecommendationUploadImage,
  },

  data() {
    return {
      message: null,
      form_data: {
        name: null,
        barcode: null,
        category_id: null,
        brand_id: null,
        description: null,
        made_in: null,
        images: [],
        is_available: true,
        is_physical: true,
        is_buyable: false,
        is_rateable: false,
        dimensions: {
          height: null,
          length: null,
          width: null,
          weight: null,
        },
      },
      isCropMain: false,
      cropImages: [],
      image_main: null,
      images: [],
      delete_images: [],
      category_options: [],
      all_category: [],
      brand_options: [],
      country_options: [],
      isEdit: false,
      isRequest: false,
      isLoading: true,
      currentId: null,
      subPermission: {},
      isPending: false,
      localMainImageLink: null,
      mainImageFile: null,
    };
  },

  watch: {
    $route(to, from) {
      if (to.name === "AddCatalog") {
        this.handleWatchRoutePage();
      }
    },
  },

  created() {
    if (this.$route.params.id && this.$route.name === "RequestCatalog") {
      this.isRequest = true;
      this.isEdit = false;
      this.currentId = this.$route.params.id;
      this.getCatalogData(this.$route.params.id);
    } else if (this.$route.params.id) {
      this.isRequest = false;
      this.isEdit = true;
      this.currentId = this.$route.params.id;
      this.getCatalogData(this.$route.params.id);
    }
  },

  beforeMount() {
    this.getData();
  },
  computed: {
    notMainImage() {
      return this.form_data.images.filter((image) => image.is_main !== true);
    },
  },
  methods: {
    handelChangeMainImage(e) {
      const file = e.target.files[0];
      const path = URL.createObjectURL(file);
      this.isCropMain = true;
      this.cropImages = { file, path };
      this.$bvModal.show("image-cropper-component");
    },
    handelCroppedMainImages(file) {
      this.localMainImageLink = file.path;
      this.form_data.images = this.form_data.images.filter(
        (image) => image.is_main !== true
      );
      this.form_data.images.push({ ...file, is_main: true });
      this.cropImages.length = 0;
    },
    handelCroppedNotMainImages(files) {
      files.forEach((file) => {
        this.form_data.images.push({ ...file, is_main: false });
      });
      this.cropImages.length = 0;
    },
    handelChangeImages(event) {
      this.isCropMain = false;
      const { files } = event.target;
      this.cropImages = [];
      files.forEach((file) => {
        const path = URL.createObjectURL(file);
        this.cropImages.push({
          file,
          path,
        });
      });
      this.$bvModal.show("image-cropper-component");
    },
    getData() {
      const endPoints = ["categories", "brands", "countries"];
      const res = endPoints.map((item) => this.$http.get(`admin/${item}`));

      Promise.all(res)
        .then((data) => {
          this.category_options = [];
          data[0].data.data.map((item) => {
            if (item.parent_id) {
              this.category_options.push({
                value: item.id,
                text: item.name,
              });
            }
          });

          this.brand_options = data[1].data.data.map((item) => ({
            value: item.id,
            text: item.name,
          }));
          this.country_options = data[2].data.data.map((item) => ({
            value: item.id,
            text: item.name,
          }));

          this.pageStatus(false, false);
        })
        .catch((error) => {
          this.$helpers.handleError(error);
          this.pageStatus(false, true);
        });
    },

    // **
    async deleteImages({ item, index }) {
      try {
        if (item.id) {
          this.delete_images.push(item.id);
          const reqToDelete = await this.$http.delete(`admin/image/${item.id}`);
          if ([200, 201].includes(reqToDelete.status)) {
            this.form_data.images = this.form_data.images.filter(
              (img) => img.id !== item.id
            );
          }
        } else {
          this.form_data.images = this.form_data.images.filter(
            (loopItem) => loopItem.path !== item.path
          );
          this.images.forEach(
            (img) => img.lastModified !== item.originalFile.lastModified
          );
        }
      } catch (error) {}
    },

    //  Clear Form Data
    clearForm() {
      for (const i in this.form_data) {
        if (i === "images") {
          this.form_data[i] = [];
        } else if (i === "dimensions") {
          this.form_data.dimensions.height = null;
          this.form_data.dimensions.length = null;
          this.form_data.dimensions.width = null;
          this.form_data.dimensions.weight = null;
        } else if (i === "is_available") {
          this.form_data.is_available = false;
        } else {
          this.form_data[i] = null;
        }
      }
    },
    handelIsMainChange({ path, id, file }) {
      if (file && !path) {
        this.localMainImageLink = file;
      } else {
        this.localMainImageLink = path;
        this.form_data.images = this.form_data.images.filter(
          (ele) => ele.path !== path
        );
      }
    },
    pageStatus(loading, error) {
      this.isLoading = loading;
      this.isError = error;
    },

    // Update page data when it changes
    handleWatchRoutePage() {
      this.isRequest = false;
      this.isEdit = false;
      this.$refs.form.reset();
      this.clearForm();
    },

    getCatalogData(id) {
      this.$http
        .get(`admin/catalogs/${id}`)
        .then((res) => {
          if (res.status === 200) {
            const { data } = res.data;
            this.form_data.name = data.name;
            this.form_data.barcode = data.barcode;
            this.form_data.description = data.description;
            this.form_data.made_in = data.made_in;
            this.form_data.category_id = data.category?.id;
            this.form_data.is_physical = data.is_physical;
            this.form_data.is_available = data.is_available;
            this.form_data.is_buyable = data.is_buyable;
            this.form_data.is_rateable = data.is_rateable;
            this.form_data.brand_id = data.brand?.id;
            this.form_data.dimensions.height = data.dimensions?.height;
            this.form_data.dimensions.length = data.dimensions?.length;
            this.form_data.dimensions.width = data.dimensions?.width;
            this.form_data.dimensions.weight = data.dimensions?.weight;
            data.images.map((file) => {
              if (file.is_main) {
                this.localMainImageLink = file.path;
                this.image_main = { path: file.path, id: file.id };
              } else {
                this.image_main = {};
                this.form_data.images.push({
                  path: file.path,
                  id: file.id,
                });
              }
            });
          }
        })
        .catch((err) => {
          this.$helpers.handleError(err);
        });
    },

    //  Submit new catalogs
    submitNewAddition() {
      this.$refs.form.validate().then((success) => {
        if (success) {
          this.isPending = true;
          const formData = this.collectData();
          this.$http
            .post("admin/catalogs", formData)
            .then((res) => {
              if (res.status === 200) {
                this.$helpers.makeToast("success", res.data.message);
                setTimeout(() => {
                  this.$router.push({ name: "CatalogList" });
                }, 1000);
                this.isPending = false;
              }
            })
            .catch((err) => {
              this.$helpers.handleError(err);

              this.isPending = false;
            });
        } else {
          this.$helpers.makeToast(
            "warning",
            this.$t("g.something_is_wrong"),
            this.$t("g.please_ensure_that_the_inputs_are_correct")
          );
        }
      });
    },

    //  edit catalogs
    submitEdit() {
      this.$refs.form.validate().then((success) => {
        if (success) {
          this.isPending = true;
          const formData = this.collectData(true);
          this.$http
            .post(`admin/catalogs/${this.currentId}?_method=put`, formData)
            .then((res) => {
              if (res.status === 200 || res.status === 201) {
                this.$helpers.makeToast(
                  "success",
                  res.data.message,
                  res.data.message
                );
                setTimeout(() => {
                  this.$router.push({ name: "CatalogList" });
                }, 500);
                this.isPending = false;
              }
            })
            .catch((err) => {
              this.$helpers.handleError(err);

              this.isPending = false;
            });
        } else {
          this.$helpers.makeToast(
            "warning",
            this.$t("g.something_is_wrong"),
            this.$t("g.please_ensure_that_the_inputs_are_correct")
          );
        }
      });
    },
    handleReset() {
      this.message = null;
    },
    handelDeleteRequest() {
      this.$refs.cReject.validate().then((success) => {
        if (success) {
          let rowData = {
            message: this.message,
          };
          this.$http
            .delete(`admin/catalogs/${this.currentId}`, {
              data: rowData,
              headers: {
                "Content-Type": "application/json",
              },
            })
            .then((res) => {
              if (res.status === 200 || res.status === 201) {
                this.cancelStatus = true;
                this.$helpers.makeToast(
                  "success",
                  res.data.message,
                  res.data.message
                );
                this.$router.push({ name: "CatalogList" });
              }
            })
            .catch((err) => {
              this.$helpers.handleError(err);
            });
        } else {
          this.$helpers.makeToast(
            "warning",
            this.$t("g.something_is_wrong"),
            this.$t("g.please_ensure_that_the_inputs_are_correct")
          );
        }
      });
    },

    //  Submit Function
    validationForm() {
      if (this.isEdit || this.isRequest) {
        return this.submitEdit();
      }
      this.submitNewAddition();
    },
    collectData(isEdit) {
      const formData = new FormData();
      if (this.isRequest) {
        formData.append("is_approved", 1);
      } else {
        for (const item in this.form_data) {
          if (
            this.form_data[item] !== null &&
            item !== "dimensions" &&
            item !== "images"
          ) {
            const boolians = [
              "is_available",
              "is_physical",
              "is_rateable",
              "is_buyable",
            ];
            if (boolians.includes(item) && this.form_data.is_physical) {
              formData.append(item, Number(this.form_data[item]));
            } else {
              formData.append(item, this.form_data[item]);
            }
          } else if (item === "images") {
            const images = this.form_data.images.map((item) => {
              return {
                is_main: item.is_main,
                file: item.file,
              };
            });
            const imagesFiles = [...images];

            if (imagesFiles.length > 0) {
              for (const item in imagesFiles) {
                if (imagesFiles[item].file) {
                  formData.append(
                    `images[${item}][file]`,
                    imagesFiles[item].file
                  );
                  formData.append(
                    `images[${item}][is_main]`,
                    Number(imagesFiles[item].is_main)
                  );
                }
              }
            }
          } else if (item === "dimensions") {
            if (this.form_data.is_physical) {
              for (const item in this.form_data.dimensions) {
                if (this.form_data.dimensions[item])
                  formData.append(
                    `dimensions[${item}]`,
                    this.form_data.dimensions[item]
                  );
              }
            }
          }
        }
        if (isEdit) {
          formData.append("lang", localStorage.getItem("lang"));
        }
      }
      return formData;
    },
  },
};
</script>

<style lang="scss">
@import "./style.scss";
</style>
