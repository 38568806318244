var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isLoading)?_c('loading'):_c('validation-observer',{ref:"form"},[_c('b-form',[_c('b-card',{staticClass:"card-add-catalog"},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.name'),"label-for":"name"}},[_c('validation-provider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"type":"text","disabled":_vm.isRequest},model:{value:(_vm.form_data.name),callback:function ($$v) {_vm.$set(_vm.form_data, "name", $$v)},expression:"form_data.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.barcode'),"label-for":"barcode"}},[_c('validation-provider',{attrs:{"name":"Barcode"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"type":"number","disabled":_vm.isRequest},model:{value:(_vm.form_data.barcode),callback:function ($$v) {_vm.$set(_vm.form_data, "barcode", $$v)},expression:"form_data.barcode"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.CountryOfManufacture'),"label-for":"CountryOfManufacture"}},[_c('validation-provider',{attrs:{"name":"CountryOfManufacture"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"type":"text","disabled":_vm.isRequest},model:{value:(_vm.form_data.made_in),callback:function ($$v) {_vm.$set(_vm.form_data, "made_in", $$v)},expression:"form_data.made_in"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.brand'),"label-for":"Brand"}},[_c('validation-provider',{attrs:{"name":"Brand","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"dir":_vm.$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr',"options":_vm.brand_options,"label":"position","state":errors.length > 0 ? false : null,"disabled":_vm.isRequest},model:{value:(_vm.form_data.brand_id),callback:function ($$v) {_vm.$set(_vm.form_data, "brand_id", $$v)},expression:"form_data.brand_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.Category'),"label-for":"Category"}},[_c('validation-provider',{attrs:{"name":"Category","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"dir":_vm.$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr',"options":_vm.category_options,"label":"position","state":errors.length > 0 ? false : null,"disabled":_vm.isRequest},model:{value:(_vm.form_data.category_id),callback:function ($$v) {_vm.$set(_vm.form_data, "category_id", $$v)},expression:"form_data.category_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.MainImage'),"label-for":"Main Image"}},[_c('validation-provider',{attrs:{"name":"Main Image"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input-main-image',{attrs:{"is-request":_vm.isRequest,"is-edit":_vm.isEdit,"image":_vm.image_main,"local-image":_vm.localMainImageLink}}),(!_vm.isRequest)?_c('b-form-file',{staticClass:"d-none",attrs:{"id":"main-file","state":errors.length > 0 ? false : null,"type":"file","plain":"","hidden":"hidden","accept":"image/*","length":"1"},on:{"change":_vm.handelChangeMainImage},model:{value:(_vm.mainImageFile),callback:function ($$v) {_vm.mainImageFile=$$v},expression:"mainImageFile"}}):_vm._e(),(!_vm.mainImageFile || _vm.mainImageFile.length == 0)?_c('RecommendationUploadImage',{attrs:{"dimensions":{ width: 1024, height: 1024 }}}):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"9"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.ImagesOfProduct'),"label-for":"Images of Product"}},[_c('input-product-images',{attrs:{"slug":_vm.$route.params.id,"is-request":_vm.isRequest,"is-edit":_vm.isEdit,"images":_vm.notMainImage},on:{"delete-images":_vm.deleteImages,"handel-is-main-change":_vm.handelIsMainChange}}),(!_vm.isRequest)?_c('b-form-file',{attrs:{"id":"product-files","type":"file","plain":"","hidden":"","accept":"image/*","multiple":""},on:{"change":_vm.handelChangeImages},model:{value:(_vm.images),callback:function ($$v) {_vm.images=$$v},expression:"images"}}):_vm._e()],1),_c('RecommendationUploadImage',{attrs:{"dimensions":{ width: 1024, height: 1024 }}})],1),_c('b-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"switches-row"},[_c('b-form-group',{attrs:{"label":_vm.$t('g.is_available'),"label-for":"is_available"}},[_c('validation-provider',{attrs:{"name":"is_available","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"name":"check-button","switch":"","disabled":_vm.isRequest},model:{value:(_vm.form_data.is_available),callback:function ($$v) {_vm.$set(_vm.form_data, "is_available", $$v)},expression:"form_data.is_available"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":_vm.$t('g.is_rateable'),"label-for":"is_rateable"}},[_c('validation-provider',{attrs:{"name":"is_rateable","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"name":"check-button","switch":"","disabled":_vm.isRequest},model:{value:(_vm.form_data.is_rateable),callback:function ($$v) {_vm.$set(_vm.form_data, "is_rateable", $$v)},expression:"form_data.is_rateable"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":_vm.$t('g.is_buyable'),"label-for":"is_buyable"}},[_c('validation-provider',{attrs:{"name":"is_buyable","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"name":"check-button","switch":"","disabled":_vm.isRequest},model:{value:(_vm.form_data.is_buyable),callback:function ($$v) {_vm.$set(_vm.form_data, "is_buyable", $$v)},expression:"form_data.is_buyable"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1)]),(_vm.form_data.is_physical)?_c('b-col',[_c('div',{staticClass:"isPhsyica-form row"},[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('catalogs.width'),"label-for":"width"}},[_c('validation-provider',{attrs:{"name":"width"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('b-input-group-text',[_vm._v(_vm._s(_vm.$t("g.cm"))+" ")])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false : null,"disabled":_vm.isRequest},model:{value:(_vm.form_data.dimensions.width),callback:function ($$v) {_vm.$set(_vm.form_data.dimensions, "width", $$v)},expression:"form_data.dimensions.width"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}],null,false,1294987399)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('catalogs.height'),"label-for":"height"}},[_c('validation-provider',{attrs:{"name":"height"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('b-input-group-text',[_vm._v(_vm._s(_vm.$t("g.cm"))+" ")])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false : null,"disabled":_vm.isRequest},model:{value:(_vm.form_data.dimensions.height),callback:function ($$v) {_vm.$set(_vm.form_data.dimensions, "height", $$v)},expression:"form_data.dimensions.height"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}],null,false,2008169534)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('catalogs.length'),"label-for":"length"}},[_c('validation-provider',{attrs:{"name":"length"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('b-input-group-text',[_vm._v(_vm._s(_vm.$t("g.cm"))+" ")])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false : null,"disabled":_vm.isRequest},model:{value:(_vm.form_data.dimensions.length),callback:function ($$v) {_vm.$set(_vm.form_data.dimensions, "length", $$v)},expression:"form_data.dimensions.length"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}],null,false,1114307613)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('catalogs.weight'),"label-for":"weigth"}},[_c('validation-provider',{attrs:{"name":"weight"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('b-input-group-text',[_vm._v(_vm._s(_vm.$t("g.kg"))+" ")])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false : null,"disabled":_vm.isRequest},model:{value:(_vm.form_data.dimensions.weight),callback:function ($$v) {_vm.$set(_vm.form_data.dimensions, "weight", $$v)},expression:"form_data.dimensions.weight"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}],null,false,3410397187)})],1)],1)],1)]):_vm._e(),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.description'),"label-for":"Brand"}},[_c('validation-provider',{attrs:{"name":"Description"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"state":errors.length > 0 ? false : null,"rows":"4","disabled":_vm.isRequest},model:{value:(_vm.form_data.description),callback:function ($$v) {_vm.$set(_vm.form_data, "description", $$v)},expression:"form_data.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"text-right"},[(_vm.isRequest)?_c('b-button',{attrs:{"variant":"outline-primary","type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.$bvModal.show('show-modal-catalog-request')}}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("g.reject")))])]):_vm._e(),_c('b-button',{staticClass:"ml-1",attrs:{"variant":"primary","type":"submit","disabled":_vm.isPending},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[(_vm.isPending)?_c('b-spinner',{attrs:{"small":"","label":"Small Spinner"}}):(_vm.isRequest)?_c('span',[_vm._v(_vm._s(_vm.$t("g.accept")))]):_c('span',[_vm._v(" "+_vm._s(_vm.isEdit ? _vm.$t("g.saveChanges") : _vm.$t("g.addCatalog")))])],1)],1)])],1)],1)],1),_c('b-modal',{attrs:{"title":_vm.$t('g.reject_catalog'),"centered":"","id":"show-modal-catalog-request","modal-class":"add-modal","title-class":"add-modal-title","header-class":"add-modal-header","footer-class":"add-modal-footer","cancel-variant":"outline-primary","hide-footer":""},on:{"close":_vm.handleReset}},[_c('div',[_c('validation-observer',{ref:"cReject"},[_c('b-form',[_c('b-form-group',{attrs:{"label":_vm.$t('g.rejection_reason')}},[_c('validation-provider',{attrs:{"name":"Rejection reason","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"rows":"5","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('g.rejection_reason')},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1),_c('b-button',{attrs:{"variant":"danger"},on:{"click":_vm.handelDeleteRequest}},[_vm._v(" "+_vm._s(_vm.$t("g.submit"))+" ")])],1)],1)],1)]),_c('image-cropper-component',{attrs:{"images-array":_vm.cropImages,"image-type":_vm.isCropMain},on:{"return-not-main-image":_vm.handelCroppedNotMainImages,"return-main-image":_vm.handelCroppedMainImages,"handel-cancel-main-image":_vm.handelCroppedMainImages,"handel-cancel-not-main-image":_vm.handelCroppedNotMainImages}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }