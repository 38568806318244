<template>
  <div class="loading">
    <div class="loadin-cube">
      <i class="layer"></i>
      <i class="layer"></i>
      <i class="layer"></i>
    </div>
    <div class="loading-text">{{ $t('loading') }}</div>
  </div>
</template>

<script>
export default {
  name: "Loading"
};
</script>

<style scoped lang="scss" src="./_index.scss"/>
