<template>
  <div class="recommendation_upload_image">
    <p :style="{color: overlay ? '#fff': '#000'}">
      {{
        `${$t("recommendation_image_dimensions")}:`
      }}
      <span>{{ `${dimensions.width} ${$t('X')} ${ dimensions.height } ${$t('px')}` }}</span>
    </p>
  </div>
</template>

<script>
export default {
  props: ["dimensions", "overlay"],
};
</script>

<style lang="scss" src="./index.scss"></style>
